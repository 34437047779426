.App {
  text-align: center;
}

p {
  margin: 5px 0;
}
.bold {
  font-weight: bold;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  text-align: center;
}
footer {
  font-size: 11px;
  color: #ccc;
  text-align: right;
}
/*результаты*/
.report-result {
  margin: 2rem auto;
}
.table {
  border-collapse: collapse;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  width: 100%;
}
.table td {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}
.table thead td {
  font-weight: bold;
  background: #dcdcdc;

}
.table thead td  > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table thead td:not(:last-child) {
  border-right: 1px solid #ccc;
}

.table thead td .sort {
  color: #a1a1a1;
}
.table thead td .sort.active {
  color: blue;
}

.table tbody tr:hover td:not(.subitog) {
  background: #e9e9e9;
}
.table td.num {
  text-align: center;
}
.table thead td.num div {
  justify-content: center;
}
.table td.sum_wo_discount,
.table td.discount_sum,
.table td.sum_with_discount,
.table td.cheques,
.table td.mid_summ,
.table td.sum_deposit
{
  text-align: right;
}
.table td.rest_name {
  font-weight: bold;
}

.table td.border-bottom {
  border-bottom: 3px solid #aaa;
}
.table td.border-top {
  border-top: 3px solid #aaa;
}
.table td.bb {
  border-bottom: 2px solid #aaa;
}
.table td.subitog {
  background: #f1f1f1;
  /*font-style: italic;*/
  border-bottom: 3px solid #aaa;
  font-weight: 300;
  text-align: right;
}
.table td.bb {
  border-bottom: 3px solid #aaa;
}
.table td.sub-subitog {
  /*border-bottom: 2px solid #aaa;*/
  /*background: #f1f1f1;*/
  color: #878787;
  font-style: italic;
  text-align: right;
  font-weight: 300;
}

/*.table td.subitog.rest_name,*/
/*.table td.subitog.day*/
/*{*/
/*  font-weight: 300;*/
/*  text-align: right;*/
/*}*/
.table td.itog {
  background: #dcdcdc;
  font-weight: bold;
  text-align: right;
}

.table td.online {
  text-align: center;
}
.table td.online.off {
  background: red;
  color: #fff;
}
.itog-smena {
  padding: 0 0 50px 0;
}
.itog-smena h2 {
  text-align: center;
}
.is-total-wrap {
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: #ccc 1px solid;
}
.is-total {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.is-total div {
  margin: 0 5px 0 0;
  font-weight: bold;
}
.is-copy {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.is-copy span {
  margin: 0 0 0 20px;
  color: green;
}

@media screen and (max-width: 800px) {
  .user-name {
    display: none;
  }
}
